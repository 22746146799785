import { default as _91slug_93DkvhLhO6BlMeta } from "/app/apps/articles/pages/advices/[adviceId]/[slug].vue?macro=true";
import { default as indexqoUofFD8LpMeta } from "/app/apps/articles/pages/advices/index.vue?macro=true";
import { default as _91topicId_93wQ32vbvyhAMeta } from "/app/apps/articles/pages/advices/topics/[topicId].vue?macro=true";
import { default as verificationxxVClvGANLMeta } from "/app/apps/site/layers/Job/pages/application/verification.vue?macro=true";
import { default as forgotten_45passwordRpZVzXqozwMeta } from "/app/apps/site/pages/auth/forgotten-password.vue?macro=true";
import { default as indexvVp5q835c9Meta } from "/app/apps/site/pages/auth/index.vue?macro=true";
import { default as login9aL5YFykZgMeta } from "/app/apps/site/pages/auth/login.vue?macro=true";
import { default as logout3ToXs4iiNrMeta } from "/app/apps/site/pages/auth/logout.vue?macro=true";
import { default as verify_45emailRvb47T6gzOMeta } from "/app/apps/site/pages/auth/verify-email.vue?macro=true";
import { default as verifyCovoxa0j2NMeta } from "/app/apps/site/pages/auth/verify.vue?macro=true";
import { default as authorJak2L365A0Meta } from "/app/apps/site/pages/calculator/author.vue?macro=true";
import { default as salaryTyS2Dt5Jx6Meta } from "/app/apps/site/pages/calculator/salary.vue?macro=true";
import { default as serviceEkzLfqk1G4Meta } from "/app/apps/site/pages/calculator/service.vue?macro=true";
import { default as configkj7mpiY1EoMeta } from "/app/apps/site/pages/debug/config.vue?macro=true";
import { default as routesSQU0r3BH8UMeta } from "/app/apps/site/pages/debug/routes.vue?macro=true";
import { default as _91employerId_93oEMx6XXYW5Meta } from "/app/apps/employers/pages/employers/[employerId].vue?macro=true";
import { default as index5nNzzZj2XKMeta } from "/app/apps/employers/pages/employers/index.vue?macro=true";
import { default as _91category_93S43E2l0ypAMeta } from "/app/apps/site/pages/faq/[category].vue?macro=true";
import { default as indexyh7btIRDb0Meta } from "/app/apps/site/pages/faq/index.vue?macro=true";
import { default as indexhfvhpQJmdsMeta } from "/app/apps/site/pages/index/index.vue?macro=true";
import { default as _91slug_93WUsuQsVLwcMeta } from "/app/apps/site/pages/info/[slug].vue?macro=true";
import { default as apply_45external_45countdowno4dScsCepgMeta } from "/app/apps/site/layers/Job/pages/job/[jobId]/[slug]/apply-external-countdown.vue?macro=true";
import { default as apply_45external_45formffRxqQBZErMeta } from "/app/apps/site/layers/Job/pages/job/[jobId]/[slug]/apply-external-form.vue?macro=true";
import { default as apply_45externalZSWdh18JwnMeta } from "/app/apps/site/layers/Job/pages/job/[jobId]/[slug]/apply-external.vue?macro=true";
import { default as applyckcqptWrm5Meta } from "/app/apps/site/layers/Job/pages/job/[jobId]/[slug]/apply.vue?macro=true";
import { default as detailsUwtPC3FJB9Meta } from "/app/apps/site/layers/Job/pages/job/[jobId]/[slug]/details.vue?macro=true";
import { default as _91jobId_93r9pWVUJYhIMeta } from "/app/apps/site/layers/Job/pages/job/[jobId].vue?macro=true";
import { default as _91slug_930wDRPfkTTpMeta } from "/app/apps/jobs/pages/jobs/[slug].vue?macro=true";
import { default as indexMZAUHAfz2hMeta } from "/app/apps/jobs/pages/jobs/index.vue?macro=true";
import { default as my_45profile_45preview09icPF9X4kMeta } from "/app/apps/site/layers/MyProfile/pages/my-profile-preview.vue?macro=true";
import { default as my_45profileOqnUgpRMHiMeta } from "/app/apps/site/layers/MyProfile/pages/my-profile.vue?macro=true";
import { default as applicationsSeLeQ1SiZOMeta } from "/app/apps/site/pages/my/applications.vue?macro=true";
import { default as documentspLgNA6kZOSMeta } from "/app/apps/site/pages/my/documents.vue?macro=true";
import { default as favorite_45jobstsEDq5mqXaMeta } from "/app/apps/site/pages/my/favorite-jobs.vue?macro=true";
import { default as notificationsCc6VMGb4P0Meta } from "/app/apps/site/pages/my/notifications.vue?macro=true";
import { default as settingsLPMr7BiiGHMeta } from "/app/apps/site/pages/my/settings.vue?macro=true";
import { default as indexFlTcufSab2Meta } from "/app/apps/site/pages/search/index.vue?macro=true";
export default [
  {
    name: "advices-adviceId-slug___mk",
    path: "/sovet/:adviceId()/:slug()",
    component: () => import("/app/apps/articles/pages/advices/[adviceId]/[slug].vue")
  },
  {
    name: "advices-adviceId-slug___hr",
    path: "/hr/savjet/:adviceId()/:slug()",
    component: () => import("/app/apps/articles/pages/advices/[adviceId]/[slug].vue")
  },
  {
    name: "advices-adviceId-slug___en",
    path: "/en/advice/:adviceId()/:slug()",
    component: () => import("/app/apps/articles/pages/advices/[adviceId]/[slug].vue")
  },
  {
    name: "advices-adviceId-slug___sl",
    path: "/sl/sovet/:adviceId()/:slug()",
    component: () => import("/app/apps/articles/pages/advices/[adviceId]/[slug].vue")
  },
  {
    name: "advices-adviceId-slug___bs",
    path: "/bs/sovet/:adviceId()/:slug()",
    component: () => import("/app/apps/articles/pages/advices/[adviceId]/[slug].vue")
  },
  {
    name: "advices___mk",
    path: "/soveti",
    component: () => import("/app/apps/articles/pages/advices/index.vue")
  },
  {
    name: "advices___hr",
    path: "/hr/savjeti",
    component: () => import("/app/apps/articles/pages/advices/index.vue")
  },
  {
    name: "advices___en",
    path: "/en/advices",
    component: () => import("/app/apps/articles/pages/advices/index.vue")
  },
  {
    name: "advices___sl",
    path: "/sl/soveti",
    component: () => import("/app/apps/articles/pages/advices/index.vue")
  },
  {
    name: "advices___bs",
    path: "/bs/soveti",
    component: () => import("/app/apps/articles/pages/advices/index.vue")
  },
  {
    name: "advices-topics-topicId___mk",
    path: "/soveti/topic/:topicId()",
    component: () => import("/app/apps/articles/pages/advices/topics/[topicId].vue")
  },
  {
    name: "advices-topics-topicId___hr",
    path: "/hr/savjeti/tema/:topicId()",
    component: () => import("/app/apps/articles/pages/advices/topics/[topicId].vue")
  },
  {
    name: "advices-topics-topicId___en",
    path: "/en/advices/topic/:topicId()",
    component: () => import("/app/apps/articles/pages/advices/topics/[topicId].vue")
  },
  {
    name: "advices-topics-topicId___sl",
    path: "/sl/soveti/topic/:topicId()",
    component: () => import("/app/apps/articles/pages/advices/topics/[topicId].vue")
  },
  {
    name: "advices-topics-topicId___bs",
    path: "/bs/soveti/topic/:topicId()",
    component: () => import("/app/apps/articles/pages/advices/topics/[topicId].vue")
  },
  {
    name: "application-verification___mk",
    path: "/application/verification",
    component: () => import("/app/apps/site/layers/Job/pages/application/verification.vue")
  },
  {
    name: "application-verification___hr",
    path: "/hr/application/verification",
    component: () => import("/app/apps/site/layers/Job/pages/application/verification.vue")
  },
  {
    name: "application-verification___en",
    path: "/en/application/verification",
    component: () => import("/app/apps/site/layers/Job/pages/application/verification.vue")
  },
  {
    name: "application-verification___sl",
    path: "/sl/application/verification",
    component: () => import("/app/apps/site/layers/Job/pages/application/verification.vue")
  },
  {
    name: "application-verification___bs",
    path: "/bs/application/verification",
    component: () => import("/app/apps/site/layers/Job/pages/application/verification.vue")
  },
  {
    name: "auth-forgotten-password___mk",
    path: "/auth/forgotten-password",
    meta: forgotten_45passwordRpZVzXqozwMeta || {},
    component: () => import("/app/apps/site/pages/auth/forgotten-password.vue")
  },
  {
    name: "auth-forgotten-password___hr",
    path: "/hr/auth/forgotten-password",
    meta: forgotten_45passwordRpZVzXqozwMeta || {},
    component: () => import("/app/apps/site/pages/auth/forgotten-password.vue")
  },
  {
    name: "auth-forgotten-password___en",
    path: "/en/auth/forgotten-password",
    meta: forgotten_45passwordRpZVzXqozwMeta || {},
    component: () => import("/app/apps/site/pages/auth/forgotten-password.vue")
  },
  {
    name: "auth-forgotten-password___sl",
    path: "/sl/auth/forgotten-password",
    meta: forgotten_45passwordRpZVzXqozwMeta || {},
    component: () => import("/app/apps/site/pages/auth/forgotten-password.vue")
  },
  {
    name: "auth-forgotten-password___bs",
    path: "/bs/auth/forgotten-password",
    meta: forgotten_45passwordRpZVzXqozwMeta || {},
    component: () => import("/app/apps/site/pages/auth/forgotten-password.vue")
  },
  {
    name: "auth___mk",
    path: "/auth",
    meta: indexvVp5q835c9Meta || {},
    component: () => import("/app/apps/site/pages/auth/index.vue")
  },
  {
    name: "auth___hr",
    path: "/hr/auth",
    meta: indexvVp5q835c9Meta || {},
    component: () => import("/app/apps/site/pages/auth/index.vue")
  },
  {
    name: "auth___en",
    path: "/en/auth",
    meta: indexvVp5q835c9Meta || {},
    component: () => import("/app/apps/site/pages/auth/index.vue")
  },
  {
    name: "auth___sl",
    path: "/sl/auth",
    meta: indexvVp5q835c9Meta || {},
    component: () => import("/app/apps/site/pages/auth/index.vue")
  },
  {
    name: "auth___bs",
    path: "/bs/auth",
    meta: indexvVp5q835c9Meta || {},
    component: () => import("/app/apps/site/pages/auth/index.vue")
  },
  {
    name: "auth-login___mk",
    path: "/prijava",
    meta: login9aL5YFykZgMeta || {},
    component: () => import("/app/apps/site/pages/auth/login.vue")
  },
  {
    name: "auth-login___hr",
    path: "/hr/prijava",
    meta: login9aL5YFykZgMeta || {},
    component: () => import("/app/apps/site/pages/auth/login.vue")
  },
  {
    name: "auth-login___en",
    path: "/en/login",
    meta: login9aL5YFykZgMeta || {},
    component: () => import("/app/apps/site/pages/auth/login.vue")
  },
  {
    name: "auth-login___sl",
    path: "/sl/prijava",
    meta: login9aL5YFykZgMeta || {},
    component: () => import("/app/apps/site/pages/auth/login.vue")
  },
  {
    name: "auth-login___bs",
    path: "/bs/prijava",
    meta: login9aL5YFykZgMeta || {},
    component: () => import("/app/apps/site/pages/auth/login.vue")
  },
  {
    name: "auth-logout___mk",
    path: "/odjava",
    component: () => import("/app/apps/site/pages/auth/logout.vue")
  },
  {
    name: "auth-logout___hr",
    path: "/hr/odjava",
    component: () => import("/app/apps/site/pages/auth/logout.vue")
  },
  {
    name: "auth-logout___en",
    path: "/en/logout",
    component: () => import("/app/apps/site/pages/auth/logout.vue")
  },
  {
    name: "auth-logout___sl",
    path: "/sl/odjava",
    component: () => import("/app/apps/site/pages/auth/logout.vue")
  },
  {
    name: "auth-logout___bs",
    path: "/bs/odjava",
    component: () => import("/app/apps/site/pages/auth/logout.vue")
  },
  {
    name: "auth-verify-email___mk",
    path: "/auth/verify-email",
    component: () => import("/app/apps/site/pages/auth/verify-email.vue")
  },
  {
    name: "auth-verify-email___hr",
    path: "/hr/auth/verify-email",
    component: () => import("/app/apps/site/pages/auth/verify-email.vue")
  },
  {
    name: "auth-verify-email___en",
    path: "/en/auth/verify-email",
    component: () => import("/app/apps/site/pages/auth/verify-email.vue")
  },
  {
    name: "auth-verify-email___sl",
    path: "/sl/auth/verify-email",
    component: () => import("/app/apps/site/pages/auth/verify-email.vue")
  },
  {
    name: "auth-verify-email___bs",
    path: "/bs/auth/verify-email",
    component: () => import("/app/apps/site/pages/auth/verify-email.vue")
  },
  {
    name: "auth-verify___mk",
    path: "/auth/verify",
    component: () => import("/app/apps/site/pages/auth/verify.vue")
  },
  {
    name: "auth-verify___hr",
    path: "/hr/auth/verify",
    component: () => import("/app/apps/site/pages/auth/verify.vue")
  },
  {
    name: "auth-verify___en",
    path: "/en/auth/verify",
    component: () => import("/app/apps/site/pages/auth/verify.vue")
  },
  {
    name: "auth-verify___sl",
    path: "/sl/auth/verify",
    component: () => import("/app/apps/site/pages/auth/verify.vue")
  },
  {
    name: "auth-verify___bs",
    path: "/bs/auth/verify",
    component: () => import("/app/apps/site/pages/auth/verify.vue")
  },
  {
    name: "calculator-author___mk",
    path: "/kalkulator/autorski-ugovor",
    component: () => import("/app/apps/site/pages/calculator/author.vue")
  },
  {
    name: "calculator-author___hr",
    path: "/hr/kalkulator/autorski-ugovor",
    component: () => import("/app/apps/site/pages/calculator/author.vue")
  },
  {
    name: "calculator-author___en",
    path: "/en/calculator/royalty-contract",
    component: () => import("/app/apps/site/pages/calculator/author.vue")
  },
  {
    name: "calculator-author___sl",
    path: "/sl/kalkulator/autorski-ugovor",
    component: () => import("/app/apps/site/pages/calculator/author.vue")
  },
  {
    name: "calculator-author___bs",
    path: "/bs/kalkulator/autorski-ugovor",
    component: () => import("/app/apps/site/pages/calculator/author.vue")
  },
  {
    name: "calculator-salary___mk",
    path: "/kalkulator/presmetka-na-plata",
    component: () => import("/app/apps/site/pages/calculator/salary.vue")
  },
  {
    name: "calculator-salary___hr",
    path: "/hr/kalkulator/presmetka-na-plata",
    component: () => import("/app/apps/site/pages/calculator/salary.vue")
  },
  {
    name: "calculator-salary___en",
    path: "/en/calculator/salary",
    component: () => import("/app/apps/site/pages/calculator/salary.vue")
  },
  {
    name: "calculator-salary___sl",
    path: "/sl/kalkulator/presmetka-na-plata",
    component: () => import("/app/apps/site/pages/calculator/salary.vue")
  },
  {
    name: "calculator-salary___bs",
    path: "/bs/kalkulator/presmetka-na-plata",
    component: () => import("/app/apps/site/pages/calculator/salary.vue")
  },
  {
    name: "calculator-service___mk",
    path: "/kalkulator/ugovor-o-djelu",
    component: () => import("/app/apps/site/pages/calculator/service.vue")
  },
  {
    name: "calculator-service___hr",
    path: "/hr/kalkulator/ugovor-o-djelu",
    component: () => import("/app/apps/site/pages/calculator/service.vue")
  },
  {
    name: "calculator-service___en",
    path: "/en/calculator/service-contract",
    component: () => import("/app/apps/site/pages/calculator/service.vue")
  },
  {
    name: "calculator-service___sl",
    path: "/sl/kalkulator/ugovor-o-djelu",
    component: () => import("/app/apps/site/pages/calculator/service.vue")
  },
  {
    name: "calculator-service___bs",
    path: "/bs/kalkulator/ugovor-o-djelu",
    component: () => import("/app/apps/site/pages/calculator/service.vue")
  },
  {
    name: "debug-config___mk",
    path: "/debug/config",
    component: () => import("/app/apps/site/pages/debug/config.vue")
  },
  {
    name: "debug-config___hr",
    path: "/hr/debug/config",
    component: () => import("/app/apps/site/pages/debug/config.vue")
  },
  {
    name: "debug-config___en",
    path: "/en/debug/config",
    component: () => import("/app/apps/site/pages/debug/config.vue")
  },
  {
    name: "debug-config___sl",
    path: "/sl/debug/config",
    component: () => import("/app/apps/site/pages/debug/config.vue")
  },
  {
    name: "debug-config___bs",
    path: "/bs/debug/config",
    component: () => import("/app/apps/site/pages/debug/config.vue")
  },
  {
    name: "debug-routes___mk",
    path: "/debug/routes",
    component: () => import("/app/apps/site/pages/debug/routes.vue")
  },
  {
    name: "debug-routes___hr",
    path: "/hr/debug/routes",
    component: () => import("/app/apps/site/pages/debug/routes.vue")
  },
  {
    name: "debug-routes___en",
    path: "/en/debug/routes",
    component: () => import("/app/apps/site/pages/debug/routes.vue")
  },
  {
    name: "debug-routes___sl",
    path: "/sl/debug/routes",
    component: () => import("/app/apps/site/pages/debug/routes.vue")
  },
  {
    name: "debug-routes___bs",
    path: "/bs/debug/routes",
    component: () => import("/app/apps/site/pages/debug/routes.vue")
  },
  {
    name: "employers-employerId___mk",
    path: "/rabotodavac/:employerId()",
    component: () => import("/app/apps/employers/pages/employers/[employerId].vue")
  },
  {
    name: "employers-employerId___hr",
    path: "/hr/poslodavac/:employerId()",
    component: () => import("/app/apps/employers/pages/employers/[employerId].vue")
  },
  {
    name: "employers-employerId___en",
    path: "/en/employer/:employerId()",
    component: () => import("/app/apps/employers/pages/employers/[employerId].vue")
  },
  {
    name: "employers-employerId___sl",
    path: "/sl/poslodavac/:employerId()",
    component: () => import("/app/apps/employers/pages/employers/[employerId].vue")
  },
  {
    name: "employers-employerId___bs",
    path: "/bs/poslodavac/:employerId()",
    component: () => import("/app/apps/employers/pages/employers/[employerId].vue")
  },
  {
    name: "employers___mk",
    path: "/rabotodavaci",
    component: () => import("/app/apps/employers/pages/employers/index.vue")
  },
  {
    name: "employers___hr",
    path: "/hr/poslodavci",
    component: () => import("/app/apps/employers/pages/employers/index.vue")
  },
  {
    name: "employers___en",
    path: "/en/employers",
    component: () => import("/app/apps/employers/pages/employers/index.vue")
  },
  {
    name: "employers___sl",
    path: "/sl/poslodavci",
    component: () => import("/app/apps/employers/pages/employers/index.vue")
  },
  {
    name: "employers___bs",
    path: "/bs/poslodavci",
    component: () => import("/app/apps/employers/pages/employers/index.vue")
  },
  {
    name: "faq-category___mk",
    path: "/cesti-prasanja/:category()",
    component: () => import("/app/apps/site/pages/faq/[category].vue")
  },
  {
    name: "faq-category___hr",
    path: "/hr/cesti-prasanja/:category()",
    component: () => import("/app/apps/site/pages/faq/[category].vue")
  },
  {
    name: "faq-category___en",
    path: "/en/faq/:category()",
    component: () => import("/app/apps/site/pages/faq/[category].vue")
  },
  {
    name: "faq-category___sl",
    path: "/sl/cesti-prasanja/:category()",
    component: () => import("/app/apps/site/pages/faq/[category].vue")
  },
  {
    name: "faq-category___bs",
    path: "/bs/cesti-prasanja/:category()",
    component: () => import("/app/apps/site/pages/faq/[category].vue")
  },
  {
    name: "faq___mk",
    path: "/cesti-prasanja",
    component: () => import("/app/apps/site/pages/faq/index.vue")
  },
  {
    name: "faq___hr",
    path: "/hr/cesti-prasanja",
    component: () => import("/app/apps/site/pages/faq/index.vue")
  },
  {
    name: "faq___en",
    path: "/en/faq",
    component: () => import("/app/apps/site/pages/faq/index.vue")
  },
  {
    name: "faq___sl",
    path: "/sl/cesti-prasanja",
    component: () => import("/app/apps/site/pages/faq/index.vue")
  },
  {
    name: "faq___bs",
    path: "/bs/cesti-prasanja",
    component: () => import("/app/apps/site/pages/faq/index.vue")
  },
  {
    name: "index___mk",
    path: "/",
    component: () => import("/app/apps/site/pages/index/index.vue")
  },
  {
    name: "index___hr",
    path: "/hr",
    component: () => import("/app/apps/site/pages/index/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/app/apps/site/pages/index/index.vue")
  },
  {
    name: "index___sl",
    path: "/sl",
    component: () => import("/app/apps/site/pages/index/index.vue")
  },
  {
    name: "index___bs",
    path: "/bs",
    component: () => import("/app/apps/site/pages/index/index.vue")
  },
  {
    name: "info-slug___mk",
    path: "/info/:slug()",
    component: () => import("/app/apps/site/pages/info/[slug].vue")
  },
  {
    name: "info-slug___hr",
    path: "/hr/info/:slug()",
    component: () => import("/app/apps/site/pages/info/[slug].vue")
  },
  {
    name: "info-slug___en",
    path: "/en/info/:slug()",
    component: () => import("/app/apps/site/pages/info/[slug].vue")
  },
  {
    name: "info-slug___sl",
    path: "/sl/info/:slug()",
    component: () => import("/app/apps/site/pages/info/[slug].vue")
  },
  {
    name: "info-slug___bs",
    path: "/bs/info/:slug()",
    component: () => import("/app/apps/site/pages/info/[slug].vue")
  },
  {
    name: "job-jobId___mk",
    path: "/rabota/:jobId()",
    component: () => import("/app/apps/site/layers/Job/pages/job/[jobId].vue"),
    children: [
  {
    name: "job-jobId-slug-apply-external-countdown___mk",
    path: ":slug()/apply-external-countdown",
    meta: apply_45external_45countdowno4dScsCepgMeta || {},
    component: () => import("/app/apps/site/layers/Job/pages/job/[jobId]/[slug]/apply-external-countdown.vue")
  },
  {
    name: "job-jobId-slug-apply-external-form___mk",
    path: ":slug()/apply-external-form",
    meta: apply_45external_45formffRxqQBZErMeta || {},
    component: () => import("/app/apps/site/layers/Job/pages/job/[jobId]/[slug]/apply-external-form.vue")
  },
  {
    name: "job-jobId-slug-apply-external___mk",
    path: ":slug()/apply-external",
    component: () => import("/app/apps/site/layers/Job/pages/job/[jobId]/[slug]/apply-external.vue")
  },
  {
    name: "job-jobId-slug-apply___mk",
    path: ":slug()/aplikacija",
    meta: applyckcqptWrm5Meta || {},
    component: () => import("/app/apps/site/layers/Job/pages/job/[jobId]/[slug]/apply.vue")
  },
  {
    name: "job-jobId-slug-details___mk",
    path: ":slug()",
    meta: detailsUwtPC3FJB9Meta || {},
    component: () => import("/app/apps/site/layers/Job/pages/job/[jobId]/[slug]/details.vue")
  }
]
  },
  {
    name: "job-jobId___hr",
    path: "/hr/posao/:jobId()",
    component: () => import("/app/apps/site/layers/Job/pages/job/[jobId].vue"),
    children: [
  {
    name: "job-jobId-slug-apply-external-countdown___hr",
    path: ":slug()/apply-external-countdown",
    meta: apply_45external_45countdowno4dScsCepgMeta || {},
    component: () => import("/app/apps/site/layers/Job/pages/job/[jobId]/[slug]/apply-external-countdown.vue")
  },
  {
    name: "job-jobId-slug-apply-external-form___hr",
    path: ":slug()/apply-external-form",
    meta: apply_45external_45formffRxqQBZErMeta || {},
    component: () => import("/app/apps/site/layers/Job/pages/job/[jobId]/[slug]/apply-external-form.vue")
  },
  {
    name: "job-jobId-slug-apply-external___hr",
    path: ":slug()/apply-external",
    component: () => import("/app/apps/site/layers/Job/pages/job/[jobId]/[slug]/apply-external.vue")
  },
  {
    name: "job-jobId-slug-apply___hr",
    path: ":slug()/prijava",
    meta: applyckcqptWrm5Meta || {},
    component: () => import("/app/apps/site/layers/Job/pages/job/[jobId]/[slug]/apply.vue")
  },
  {
    name: "job-jobId-slug-details___hr",
    path: ":slug()",
    meta: detailsUwtPC3FJB9Meta || {},
    component: () => import("/app/apps/site/layers/Job/pages/job/[jobId]/[slug]/details.vue")
  }
]
  },
  {
    name: "job-jobId___en",
    path: "/en/job/:jobId()",
    component: () => import("/app/apps/site/layers/Job/pages/job/[jobId].vue"),
    children: [
  {
    name: "job-jobId-slug-apply-external-countdown___en",
    path: ":slug()/apply-external-countdown",
    meta: apply_45external_45countdowno4dScsCepgMeta || {},
    component: () => import("/app/apps/site/layers/Job/pages/job/[jobId]/[slug]/apply-external-countdown.vue")
  },
  {
    name: "job-jobId-slug-apply-external-form___en",
    path: ":slug()/apply-external-form",
    meta: apply_45external_45formffRxqQBZErMeta || {},
    component: () => import("/app/apps/site/layers/Job/pages/job/[jobId]/[slug]/apply-external-form.vue")
  },
  {
    name: "job-jobId-slug-apply-external___en",
    path: ":slug()/apply-external",
    component: () => import("/app/apps/site/layers/Job/pages/job/[jobId]/[slug]/apply-external.vue")
  },
  {
    name: "job-jobId-slug-apply___en",
    path: ":slug()/apply",
    meta: applyckcqptWrm5Meta || {},
    component: () => import("/app/apps/site/layers/Job/pages/job/[jobId]/[slug]/apply.vue")
  },
  {
    name: "job-jobId-slug-details___en",
    path: ":slug()",
    meta: detailsUwtPC3FJB9Meta || {},
    component: () => import("/app/apps/site/layers/Job/pages/job/[jobId]/[slug]/details.vue")
  }
]
  },
  {
    name: "job-jobId___sl",
    path: "/sl/rabota/:jobId()",
    component: () => import("/app/apps/site/layers/Job/pages/job/[jobId].vue"),
    children: [
  {
    name: "job-jobId-slug-apply-external-countdown___sl",
    path: ":slug()/apply-external-countdown",
    meta: apply_45external_45countdowno4dScsCepgMeta || {},
    component: () => import("/app/apps/site/layers/Job/pages/job/[jobId]/[slug]/apply-external-countdown.vue")
  },
  {
    name: "job-jobId-slug-apply-external-form___sl",
    path: ":slug()/apply-external-form",
    meta: apply_45external_45formffRxqQBZErMeta || {},
    component: () => import("/app/apps/site/layers/Job/pages/job/[jobId]/[slug]/apply-external-form.vue")
  },
  {
    name: "job-jobId-slug-apply-external___sl",
    path: ":slug()/apply-external",
    component: () => import("/app/apps/site/layers/Job/pages/job/[jobId]/[slug]/apply-external.vue")
  },
  {
    name: "job-jobId-slug-apply___sl",
    path: ":slug()/aplikacija",
    meta: applyckcqptWrm5Meta || {},
    component: () => import("/app/apps/site/layers/Job/pages/job/[jobId]/[slug]/apply.vue")
  },
  {
    name: "job-jobId-slug-details___sl",
    path: ":slug()",
    meta: detailsUwtPC3FJB9Meta || {},
    component: () => import("/app/apps/site/layers/Job/pages/job/[jobId]/[slug]/details.vue")
  }
]
  },
  {
    name: "job-jobId___bs",
    path: "/bs/rabota/:jobId()",
    component: () => import("/app/apps/site/layers/Job/pages/job/[jobId].vue"),
    children: [
  {
    name: "job-jobId-slug-apply-external-countdown___bs",
    path: ":slug()/apply-external-countdown",
    meta: apply_45external_45countdowno4dScsCepgMeta || {},
    component: () => import("/app/apps/site/layers/Job/pages/job/[jobId]/[slug]/apply-external-countdown.vue")
  },
  {
    name: "job-jobId-slug-apply-external-form___bs",
    path: ":slug()/apply-external-form",
    meta: apply_45external_45formffRxqQBZErMeta || {},
    component: () => import("/app/apps/site/layers/Job/pages/job/[jobId]/[slug]/apply-external-form.vue")
  },
  {
    name: "job-jobId-slug-apply-external___bs",
    path: ":slug()/apply-external",
    component: () => import("/app/apps/site/layers/Job/pages/job/[jobId]/[slug]/apply-external.vue")
  },
  {
    name: "job-jobId-slug-apply___bs",
    path: ":slug()/aplikacija",
    meta: applyckcqptWrm5Meta || {},
    component: () => import("/app/apps/site/layers/Job/pages/job/[jobId]/[slug]/apply.vue")
  },
  {
    name: "job-jobId-slug-details___bs",
    path: ":slug()",
    meta: detailsUwtPC3FJB9Meta || {},
    component: () => import("/app/apps/site/layers/Job/pages/job/[jobId]/[slug]/details.vue")
  }
]
  },
  {
    name: "jobs-slug___mk",
    path: "/rabotni-mesta/:slug()",
    component: () => import("/app/apps/jobs/pages/jobs/[slug].vue")
  },
  {
    name: "jobs-slug___hr",
    path: "/hr/poslovi/:slug()",
    component: () => import("/app/apps/jobs/pages/jobs/[slug].vue")
  },
  {
    name: "jobs-slug___en",
    path: "/en/jobs/:slug()",
    component: () => import("/app/apps/jobs/pages/jobs/[slug].vue")
  },
  {
    name: "jobs-slug___sl",
    path: "/sl/poslovi/:slug()",
    component: () => import("/app/apps/jobs/pages/jobs/[slug].vue")
  },
  {
    name: "jobs-slug___bs",
    path: "/bs/poslovi/:slug()",
    component: () => import("/app/apps/jobs/pages/jobs/[slug].vue")
  },
  {
    name: "jobs___mk",
    path: "/rabotni-mesta",
    component: () => import("/app/apps/jobs/pages/jobs/index.vue")
  },
  {
    name: "jobs___hr",
    path: "/hr/poslovi",
    component: () => import("/app/apps/jobs/pages/jobs/index.vue")
  },
  {
    name: "jobs___en",
    path: "/en/jobs",
    component: () => import("/app/apps/jobs/pages/jobs/index.vue")
  },
  {
    name: "jobs___sl",
    path: "/sl/poslovi",
    component: () => import("/app/apps/jobs/pages/jobs/index.vue")
  },
  {
    name: "jobs___bs",
    path: "/bs/poslovi",
    component: () => import("/app/apps/jobs/pages/jobs/index.vue")
  },
  {
    name: "my-profile-preview___mk",
    path: "/my-profile-preview",
    meta: my_45profile_45preview09icPF9X4kMeta || {},
    component: () => import("/app/apps/site/layers/MyProfile/pages/my-profile-preview.vue")
  },
  {
    name: "my-profile-preview___hr",
    path: "/hr/my-profile-preview",
    meta: my_45profile_45preview09icPF9X4kMeta || {},
    component: () => import("/app/apps/site/layers/MyProfile/pages/my-profile-preview.vue")
  },
  {
    name: "my-profile-preview___en",
    path: "/en/my-profile-preview",
    meta: my_45profile_45preview09icPF9X4kMeta || {},
    component: () => import("/app/apps/site/layers/MyProfile/pages/my-profile-preview.vue")
  },
  {
    name: "my-profile-preview___sl",
    path: "/sl/my-profile-preview",
    meta: my_45profile_45preview09icPF9X4kMeta || {},
    component: () => import("/app/apps/site/layers/MyProfile/pages/my-profile-preview.vue")
  },
  {
    name: "my-profile-preview___bs",
    path: "/bs/my-profile-preview",
    meta: my_45profile_45preview09icPF9X4kMeta || {},
    component: () => import("/app/apps/site/layers/MyProfile/pages/my-profile-preview.vue")
  },
  {
    name: "my-profile___mk",
    path: "/moj-profil",
    meta: my_45profileOqnUgpRMHiMeta || {},
    component: () => import("/app/apps/site/layers/MyProfile/pages/my-profile.vue")
  },
  {
    name: "my-profile___hr",
    path: "/hr/moj-profil",
    meta: my_45profileOqnUgpRMHiMeta || {},
    component: () => import("/app/apps/site/layers/MyProfile/pages/my-profile.vue")
  },
  {
    name: "my-profile___en",
    path: "/en/my-profile",
    meta: my_45profileOqnUgpRMHiMeta || {},
    component: () => import("/app/apps/site/layers/MyProfile/pages/my-profile.vue")
  },
  {
    name: "my-profile___sl",
    path: "/sl/moj-profil",
    meta: my_45profileOqnUgpRMHiMeta || {},
    component: () => import("/app/apps/site/layers/MyProfile/pages/my-profile.vue")
  },
  {
    name: "my-profile___bs",
    path: "/bs/moj-profil",
    meta: my_45profileOqnUgpRMHiMeta || {},
    component: () => import("/app/apps/site/layers/MyProfile/pages/my-profile.vue")
  },
  {
    name: "my-applications___mk",
    path: "/moj-profil/aplikacii",
    meta: applicationsSeLeQ1SiZOMeta || {},
    component: () => import("/app/apps/site/pages/my/applications.vue")
  },
  {
    name: "my-applications___hr",
    path: "/hr/moj-profil/aplikacii",
    meta: applicationsSeLeQ1SiZOMeta || {},
    component: () => import("/app/apps/site/pages/my/applications.vue")
  },
  {
    name: "my-applications___en",
    path: "/en/my-profile/applications",
    meta: applicationsSeLeQ1SiZOMeta || {},
    component: () => import("/app/apps/site/pages/my/applications.vue")
  },
  {
    name: "my-applications___sl",
    path: "/sl/moj-profil/aplikacii",
    meta: applicationsSeLeQ1SiZOMeta || {},
    component: () => import("/app/apps/site/pages/my/applications.vue")
  },
  {
    name: "my-applications___bs",
    path: "/bs/moj-profil/aplikacii",
    meta: applicationsSeLeQ1SiZOMeta || {},
    component: () => import("/app/apps/site/pages/my/applications.vue")
  },
  {
    name: "my-documents___mk",
    path: "/moj-profil/dokumenti",
    meta: documentspLgNA6kZOSMeta || {},
    component: () => import("/app/apps/site/pages/my/documents.vue")
  },
  {
    name: "my-documents___hr",
    path: "/hr/moj-profil/dokumenti",
    meta: documentspLgNA6kZOSMeta || {},
    component: () => import("/app/apps/site/pages/my/documents.vue")
  },
  {
    name: "my-documents___en",
    path: "/en/my-profile/documents",
    meta: documentspLgNA6kZOSMeta || {},
    component: () => import("/app/apps/site/pages/my/documents.vue")
  },
  {
    name: "my-documents___sl",
    path: "/sl/moj-profil/dokumenti",
    meta: documentspLgNA6kZOSMeta || {},
    component: () => import("/app/apps/site/pages/my/documents.vue")
  },
  {
    name: "my-documents___bs",
    path: "/bs/moj-profil/dokumenti",
    meta: documentspLgNA6kZOSMeta || {},
    component: () => import("/app/apps/site/pages/my/documents.vue")
  },
  {
    name: "my-favorite-jobs___mk",
    path: "/moj-profil/zacuvani-oglasi",
    meta: favorite_45jobstsEDq5mqXaMeta || {},
    component: () => import("/app/apps/site/pages/my/favorite-jobs.vue")
  },
  {
    name: "my-favorite-jobs___hr",
    path: "/hr/moj-profil/zacuvani-oglasi",
    meta: favorite_45jobstsEDq5mqXaMeta || {},
    component: () => import("/app/apps/site/pages/my/favorite-jobs.vue")
  },
  {
    name: "my-favorite-jobs___en",
    path: "/en/my-profile/saved-jobs",
    meta: favorite_45jobstsEDq5mqXaMeta || {},
    component: () => import("/app/apps/site/pages/my/favorite-jobs.vue")
  },
  {
    name: "my-favorite-jobs___sl",
    path: "/sl/moj-profil/zacuvani-oglasi",
    meta: favorite_45jobstsEDq5mqXaMeta || {},
    component: () => import("/app/apps/site/pages/my/favorite-jobs.vue")
  },
  {
    name: "my-favorite-jobs___bs",
    path: "/bs/moj-profil/zacuvani-oglasi",
    meta: favorite_45jobstsEDq5mqXaMeta || {},
    component: () => import("/app/apps/site/pages/my/favorite-jobs.vue")
  },
  {
    name: "my-notifications___mk",
    path: "/moj-profil/izvestuvanja",
    meta: notificationsCc6VMGb4P0Meta || {},
    component: () => import("/app/apps/site/pages/my/notifications.vue")
  },
  {
    name: "my-notifications___hr",
    path: "/hr/moj-profil/izvestuvanja",
    meta: notificationsCc6VMGb4P0Meta || {},
    component: () => import("/app/apps/site/pages/my/notifications.vue")
  },
  {
    name: "my-notifications___en",
    path: "/en/my-profile/notifications",
    meta: notificationsCc6VMGb4P0Meta || {},
    component: () => import("/app/apps/site/pages/my/notifications.vue")
  },
  {
    name: "my-notifications___sl",
    path: "/sl/moj-profil/izvestuvanja",
    meta: notificationsCc6VMGb4P0Meta || {},
    component: () => import("/app/apps/site/pages/my/notifications.vue")
  },
  {
    name: "my-notifications___bs",
    path: "/bs/moj-profil/izvestuvanja",
    meta: notificationsCc6VMGb4P0Meta || {},
    component: () => import("/app/apps/site/pages/my/notifications.vue")
  },
  {
    name: "my-settings___mk",
    path: "/moj-profil/postavki",
    meta: settingsLPMr7BiiGHMeta || {},
    component: () => import("/app/apps/site/pages/my/settings.vue")
  },
  {
    name: "my-settings___hr",
    path: "/hr/moj-profil/postavki",
    meta: settingsLPMr7BiiGHMeta || {},
    component: () => import("/app/apps/site/pages/my/settings.vue")
  },
  {
    name: "my-settings___en",
    path: "/en/my-profile/settings",
    meta: settingsLPMr7BiiGHMeta || {},
    component: () => import("/app/apps/site/pages/my/settings.vue")
  },
  {
    name: "my-settings___sl",
    path: "/sl/moj-profil/postavki",
    meta: settingsLPMr7BiiGHMeta || {},
    component: () => import("/app/apps/site/pages/my/settings.vue")
  },
  {
    name: "my-settings___bs",
    path: "/bs/moj-profil/postavki",
    meta: settingsLPMr7BiiGHMeta || {},
    component: () => import("/app/apps/site/pages/my/settings.vue")
  },
  {
    name: "search___mk",
    path: "/prebaruvanje-na-rabotni-mesta",
    meta: indexFlTcufSab2Meta || {},
    component: () => import("/app/apps/site/pages/search/index.vue")
  },
  {
    name: "search___hr",
    path: "/hr/prebaruvanje-na-rabotni-mesta",
    meta: indexFlTcufSab2Meta || {},
    component: () => import("/app/apps/site/pages/search/index.vue")
  },
  {
    name: "search___en",
    path: "/en/job-search",
    meta: indexFlTcufSab2Meta || {},
    component: () => import("/app/apps/site/pages/search/index.vue")
  },
  {
    name: "search___sl",
    path: "/sl/prebaruvanje-na-rabotni-mesta",
    meta: indexFlTcufSab2Meta || {},
    component: () => import("/app/apps/site/pages/search/index.vue")
  },
  {
    name: "search___bs",
    path: "/bs/prebaruvanje-na-rabotni-mesta",
    meta: indexFlTcufSab2Meta || {},
    component: () => import("/app/apps/site/pages/search/index.vue")
  }
]